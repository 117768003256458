import '../../sass/blocks/block-thank-you.scss';
// select the target node
// @ts-ignore
const elements = document.getElementsByClassName('wpcf7-form');
const targetNode = elements.item(0);
const wrapperNode = document.getElementsByClassName('wrapper')[0];
const closeButton = document.getElementsByClassName('thank-you__close')[0];
const popupContent = document.getElementsByClassName('thank-you__span');
let regionAttr;

function addUtmParametersToLinks() {
    const utmMedium = sessionStorage.getItem('utm_medium');
    const utmSource = sessionStorage.getItem('utm_source');
    const utmCampaign = sessionStorage.getItem('utm_campaign');

    if (utmMedium && utmSource && utmCampaign) {
        const utmParams = `utm_medium=${encodeURIComponent(
            utmMedium
        )}&utm_source=${encodeURIComponent(
            utmSource
        )}&utm_campaign=${encodeURIComponent(utmCampaign)}`;

        for (let i = 0; i < popupContent.length; i++) {
            const links = popupContent[i].getElementsByTagName('a');
            for (let j = 0; j < links.length; j++) {
                const link = links[j];
                const url = new URL(link.href);
                if (!url.search.includes(utmParams)) {
                    url.search += (url.search ? '&' : '?') + utmParams;
                    link.href = url.toString();
                }
            }
        }
    }
}

if (targetNode) {
    targetNode.addEventListener('submit', (event) => {
        event.preventDefault();
        const region = targetNode.querySelector('#interested');
        if (region) {
            regionAttr = region.textContent;
        }
    });

    // create an observer instance
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === 'class') {
                const { classList } = mutation.target;
                if (classList.contains('invalid')) {
                    const optionsContainers = document.querySelectorAll(
                        '.custom-select-wrapper'
                    );
                    optionsContainers.forEach((optionsContainer) => {
                        const notValid =
                            optionsContainer.querySelector('.hidden-field');
                        const customSelect = optionsContainer.querySelector(
                            '.custom-select-form-element'
                        );

                        if (notValid && customSelect) {
                            setTimeout(() => {
                                if (
                                    notValid.classList.contains(
                                        'wpcf7-not-valid'
                                    )
                                ) {
                                    customSelect.classList.add(
                                        'wpcf7-not-valid'
                                    );
                                } else {
                                    customSelect.classList.remove(
                                        'wpcf7-not-valid'
                                    );
                                }
                            }, 100); // Set your desired timeout in milliseconds
                        }
                    });
                }
                if (classList.contains('sent')) {
                    // do something with targetNode or mutation.target
                    if (
                        regionAttr === 'New-York' ||
                        regionAttr === 'Minnesota'
                    ) {
                        wrapperNode.classList.add('sent');
                        wrapperNode.classList.add('us');
                    } else {
                        wrapperNode.classList.add('sent');
                    }
                    wrapperNode.classList.remove('apply');

                    const optionsContainers = document.querySelectorAll(
                        '.custom-select-wrapper'
                    );
                    optionsContainers.forEach((optionsContainer) => {
                        const selectedOption =
                            optionsContainer.querySelector('.clear-select');
                        const option =
                            optionsContainer.querySelector('.options');

                        if (option) {
                            const placeholder =
                                option.getAttribute('data-placeholder');
                            if (selectedOption) {
                                selectedOption.textContent = placeholder;
                            }
                        }
                    });

                    const targetInputs =
                        document.querySelectorAll('.hidden-field');
                    if (targetInputs) {
                        targetInputs.forEach((input) => {
                            // eslint-disable-next-line
                            input.value = '';
                        });
                    }
                    addUtmParametersToLinks();
                }
            }
        });
    });

    // configuration of the observer:
    const config = { attributes: true, childList: false, characterData: false };

    // pass in the target node, as well as the observer options
    observer.observe(targetNode, config);

    // add event listener to closeButton
    if (closeButton) {
        closeButton.addEventListener('click', (e) => {
            e.preventDefault();
            wrapperNode.classList.remove('sent');
            elements[0].classList.remove('sent');
            observer.disconnect();
        });
    } else {
        console.log('Close button not found.');
    }
} else {
    console.log('Element not found.');
}
